<template>
  <span v-if="prefecture && locality" class="text-normal">{{ prefecture }}・{{ locality }}</span>
</template>

<script>
import { getLocalityFromAddress, getPrefecureFromAddress } from '@/utils/string'
export default {
  props: {
    address: String
  },
  computed: {
    prefecture () {
      return getPrefecureFromAddress(this.address)
    },
    locality () {
      return getLocalityFromAddress(this.address)
    }
  }
}
</script>

<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 34.436 34.436">
  <g id="Group_5237" data-name="Group 5237" transform="translate(-76.973 -151.5)">
    <path id="Path_4539" data-name="Path 4539" d="M46.649,19.344H43.863V18.229a2.229,2.229,0,0,0-4.458,0v1.115H34.947V18.229a2.229,2.229,0,1,0-4.458,0v1.115H26.031V18.229a2.229,2.229,0,1,0-4.458,0v1.115H18.786A2.786,2.786,0,0,0,16,22.13V44.42a2.786,2.786,0,0,0,2.786,2.786h15.6a.557.557,0,1,0,0-1.115h-15.6a1.677,1.677,0,0,1-1.672-1.672V27.145H48.321v9.473a.557.557,0,1,0,1.115,0V22.13A2.786,2.786,0,0,0,46.649,19.344Zm-6.13-1.115a1.115,1.115,0,1,1,2.229,0v3.344a1.115,1.115,0,1,1-2.229,0Zm-8.916,0a1.115,1.115,0,1,1,2.229,0v3.344a1.115,1.115,0,1,1-2.229,0Zm-8.916,0a1.115,1.115,0,1,1,2.229,0v3.344a1.115,1.115,0,0,1-2.229,0Zm25.634,7.8H17.115v-3.9a1.677,1.677,0,0,1,1.672-1.672h2.786v1.115a2.229,2.229,0,0,0,4.458,0V20.458h4.458v1.115a2.229,2.229,0,1,0,4.458,0V20.458H39.4v1.115a2.229,2.229,0,0,0,4.458,0V20.458h2.786a1.677,1.677,0,0,1,1.672,1.672Z" transform="translate(61.473 136)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4540" data-name="Path 4540" d="M180.458,217.115A1.114,1.114,0,0,0,179.344,216h-2.229A1.114,1.114,0,0,0,176,217.115v1.672a1.114,1.114,0,0,0,1.115,1.115h2.229a1.114,1.114,0,0,0,1.115-1.115Zm-3.344,1.672v-1.672h2.229v1.672Z" transform="translate(-87.382 -50.068)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4541" data-name="Path 4541" d="M84.458,217.115A1.114,1.114,0,0,0,83.344,216H81.115A1.114,1.114,0,0,0,80,217.115v1.672a1.114,1.114,0,0,0,1.115,1.115h2.229a1.114,1.114,0,0,0,1.115-1.115Zm-3.344,1.672v-1.672h2.229v1.672Z" transform="translate(1.931 -50.068)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4542" data-name="Path 4542" d="M371.344,219.9a1.114,1.114,0,0,0,1.115-1.115v-1.672A1.114,1.114,0,0,0,371.344,216h-2.229A1.114,1.114,0,0,0,368,217.115v1.672a1.114,1.114,0,0,0,1.115,1.115Zm-2.229-2.786h2.229v1.672h-2.229Z" transform="translate(-266.008 -50.068)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4543" data-name="Path 4543" d="M180.458,289.115A1.114,1.114,0,0,0,179.344,288h-2.229A1.114,1.114,0,0,0,176,289.115v1.672a1.114,1.114,0,0,0,1.115,1.115h2.229a1.114,1.114,0,0,0,1.115-1.115Zm-3.344,1.672v-1.672h2.229v1.672Z" transform="translate(-87.382 -117.053)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4544" data-name="Path 4544" d="M84.458,289.115A1.114,1.114,0,0,0,83.344,288H81.115A1.114,1.114,0,0,0,80,289.115v1.672a1.114,1.114,0,0,0,1.115,1.115h2.229a1.114,1.114,0,0,0,1.115-1.115Zm-3.344,1.672v-1.672h2.229v1.672Z" transform="translate(1.931 -117.053)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4545" data-name="Path 4545" d="M179.344,360h-2.229A1.114,1.114,0,0,0,176,361.115v1.672a1.114,1.114,0,0,0,1.115,1.115h2.229a1.114,1.114,0,0,0,1.115-1.115v-1.672A1.114,1.114,0,0,0,179.344,360Zm-2.229,2.786v-1.672h2.229v1.672Z" transform="translate(-87.382 -184.038)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4546" data-name="Path 4546" d="M273.115,219.9h2.229a1.114,1.114,0,0,0,1.115-1.115v-1.672A1.114,1.114,0,0,0,275.344,216h-2.229A1.114,1.114,0,0,0,272,217.115v1.672A1.114,1.114,0,0,0,273.115,219.9Zm0-2.786h2.229v1.672h-2.229Z" transform="translate(-176.695 -50.068)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4547" data-name="Path 4547" d="M272,290.786a1.114,1.114,0,0,0,1.115,1.115.557.557,0,1,0,0-1.115v-1.672h2.229a.557.557,0,1,0,0-1.115h-2.229A1.114,1.114,0,0,0,272,289.115Z" transform="translate(-176.695 -117.053)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4548" data-name="Path 4548" d="M83.344,360H81.115A1.114,1.114,0,0,0,80,361.115v1.672a1.114,1.114,0,0,0,1.115,1.115h2.229a1.114,1.114,0,0,0,1.115-1.115v-1.672A1.114,1.114,0,0,0,83.344,360Zm-2.229,2.786v-1.672h2.229v1.672Z" transform="translate(1.931 -184.038)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4549" data-name="Path 4549" d="M295.244,288a7.244,7.244,0,1,0,7.244,7.244A7.244,7.244,0,0,0,295.244,288Zm0,13.374a6.13,6.13,0,1,1,6.13-6.13A6.13,6.13,0,0,1,295.244,301.374Z" transform="translate(-191.58 -117.053)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <path id="Path_4550" data-name="Path 4550" d="M341.41,356.374l-4.334,4.334-1.97-1.97a.557.557,0,0,0-.788.788l2.364,2.364a.557.557,0,0,0,.788,0l4.728-4.728a.557.557,0,0,0-.788-.788Z" transform="translate(-234.526 -180.52)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'IconSelectDate',
  props: {
    width: {
      type: Number,
      default: 34.436
    },
    height: {
      type: Number,
      default: 34.436
    },
    color: {
      type: String,
      default: 'var(--color__primary-light)'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
